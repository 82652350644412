<template lang="html">
  <div class="candle-form__step-content" v-if="globalSettings">
    <div class="candle-form__heading">
      <Heading
        :text="globalSettings['labels_and_titles']['choose_value']"
        :hType="2"
        textAlign="center"
      />
    </div>
    <div
      class="candle-form__description"
      v-html="globalSettings['labels_and_titles']['choose_value_message']"
    ></div>
    <div class="candle-form__prices">
      <div
        class="candle-form__prices-item"
        v-for="checkbox in donationCandles.priceVariants"
        :key="checkbox.id"
      >
        <RadioPanel :inputValue="checkbox.id" v-model="selectedOptions">
          <span v-html="checkbox.label"></span>
        </RadioPanel>
      </div>
    </div>
    <div class="candle-form__buttons">
      <div class="candle-form__button">
        <Button @click="nextStep(3)">
          <span>{{ globalSettings['button_names']['next_step'] }}</span>
        </Button>
      </div>
      <div class="candle-form__button">
        <Button styleButton="border" @click="backStep(1)">
          <span>{{ globalSettings['button_names']['back_button'] }}</span>
        </Button>
      </div>
    </div>
  </div>
  <ModalWindow :show="showModal" @closeModal="closeModal">
    <div class="candle-form__modal">
      <div class="candle-form__modal-heading">
        <Heading text="Ihre Beitragg" :hType="2" textAlign="center" />
      </div>
      <div class="candle-form__modal-range">
        <RangeInput
          :value="2"
          :min="2"
          :max="1000"
          units="€"
          @onSlide="changeCustomValue"
        />
      </div>
      <div class="candle-form__custom-text">
        {{ donationCandles.customText }}

        <span>{{ calculateDurationLight }}</span>
      </div>
      <div class="candle-form__modal-buttons">
        <div class="candle-form__modal-button">
          <Button @click="applyCustomPrice" :disabled="!customPrice">
            <span>Zur Kasse</span>
          </Button>
        </div>
        <div class="candle-form__modal-button">
          <Button styleButton="border" @click="cancelCustomPrice">
            <span>Abbrechnen</span>
          </Button>
        </div>
      </div>
    </div>
  </ModalWindow>
</template>

<script>
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue';
import Heading from '@/components/Heading/Heading.vue';
import Button from '@/components/Button/Button.vue';
import RadioPanel from '@/components/RadioPanel/RadioPanel.vue';
import RangeInput from '@/components/RangeInput/RangeInput.vue';

export default {
  name: 'CandleFormStep2',
  components: {
    ModalWindow,
    Heading,
    Button,
    RadioPanel,
    RangeInput,
  },
  data() {
    return {
      selectedOptions: this.$store.state.donationCandles.formData.price,
      showModal: false,
      customPrice: 2,
    };
  },
  watch: {
    selectedOptions(val) {
      this.$store.commit('donationCandles/setFormDataPrice', val);
      // if (val === '4') {
      //   this.showModal = true;
      // }
    },
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    donationCandles() {
      return this.$store.state.donationCandles;
    },
    calculateDurationLight() {
      let duration = 0;
      let typeDuration = 'Stunden';
      if (this.customPrice < 5) {
        duration = this.customPrice / 2;
      } else if (this.customPrice >= 5 && this.customPrice < 10) {
        duration = this.customPrice / 5;
        typeDuration = 'Tag';
      } else {
        duration = this.customPrice / 10;
        typeDuration = 'Woche';
      }
      return `${duration} ${typeDuration}`;
    },
  },
  methods: {
    nextStep(index) {
      this.$store.commit('donationCandles/setPassedStep', index - 1);
      this.$store.commit('donationCandles/setCurrentStep', index);
    },
    backStep(index) {
      this.$store.commit('donationCandles/changeStepStatePassed', {
        index: index,
        payload: false,
      });
      this.$store.commit('donationCandles/setCurrentStep', index);
      this.$store.commit('donationCandles/setPreviewIsShow', true);
    },
    closeModal() {
      this.showModal = false;
    },
    changeCustomValue(val) {
      this.customPrice = Number(val);
    },
    cancelCustomPrice() {
      this.selectedOptions = '2';
      this.closeModal();
    },
    applyCustomPrice() {
      if (!this.customPrice) return;
      this.$store.commit(
        'donationCandles/setCustomPriceValue',
        this.customPrice
      );
      this.closeModal();
      this.nextStep(3);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
