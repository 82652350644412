<template lang="html">
  <div class="range-slider">
    <div class="range-slider__value">
      <input
        type="number"
        v-model="sliderValue"
        :min="min"
        :max="max"
        :step="step"
        @input="onSlide()"
      />
    </div>
    <div class="range-slider__slider">
      <div class="range-slider__bg" :style="{ width: width + '%' }"></div>
      <input
        type="range"
        v-model="sliderValue"
        :min="min"
        :max="max"
        :step="step"
        @input="onSlide()"
      />
    </div>

    <div class="range-slider__min-max">
      <span class="range-slider__units">{{ min }} {{ units }}</span>
      <span class="range-slider__units">{{ max }} {{ units }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RangeInput',
  props: {
    value: {
      type: Number,
      default: 0,
      required: false,
    },
    min: {
      type: Number,
      default: 0,
      required: true,
    },
    max: {
      type: Number,
      default: 100,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
      required: false,
    },
    units: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      sliderValue: this.value,
      width: 0,
    };
  },
  watch: {
    sliderValue(val) {
      if (!val) {
        this.sliderValue = this.min;
      }
      this.calcWidth();
    },
  },
  created() {
    this.calcWidth();
  },
  methods: {
    calcWidth() {
      this.width = (
        ((this.sliderValue - this.min) * 100) /
        (this.max - this.min)
      ).toFixed(2);
      if (this.width > 100) {
        this.width = 100;
      }
    },
    onSlide() {
      if (this.sliderValue === 0) {
        if (this.step) {
          if (this.step < this.min) {
            this.sliderValue = this.step;
          }
        } else {
          this.sliderValue = 1;
        }
      }

      this.calcWidth();

      this.$emit('onSlide', this.sliderValue);
    },
  },
};
</script>

<style lang="scss">
@import 'styles';
</style>
